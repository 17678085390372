import Header from "./Header";
// import WelcomePage from './WelcomePage';
import DigitalWonder from "./DigitalWonder";
import Discover from "./Discover";
import Footer from "./Footer";
import ExploreMeta from "./ExoloreMeta";
import JoinUs from "./JoinUs";
import WelcomePageCarousel from "./WelcomeCarousel";
import WhyAtlaniss from "./WhyAtlanis";

const LandingPage = () => {
  return (
    <>
      <Header />
      <WelcomePageCarousel />
      <DigitalWonder />
      <Discover />
      <WhyAtlaniss />
      <ExploreMeta />
      <JoinUs />
      <Footer />

    </>
  );
};

export default LandingPage;
