import { useState } from "react";
import sail from "../images/Sail.gif";
import wavegif from "../images/wavegif.gif";

const JoinUs = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log("Form submitted:", { firstName, lastName, email });
  };

  return (
    <>
      <div
        className="flex flex-col items-center justify-center min-h-screen bg-[#080D13] space-y-16 pb-16 md:pb-20"
        name="/contact"
      >
        <img src={wavegif} className="w-[84px]" alt="" />
        <h1 className="text-white px-2 text-3xl lg:text-heading font-extrabold text-center">
          Join the Future with Atlantis
          <br />
          <span className="font-normal bg-gradient-to-r from-[#CD0E59] to-[#11D4D9] bg-clip-text text-transparent py-2">
            Digital Twin?
          </span>
        </h1>

        <div className="w-auto xl:w-[1318px] xl:h-[482px] max-h-full py-10 md:py-24 px-4 lg:px-24 rounded-[24px] lg:rounded-[58px] bg-[#070C12] border border-[#11D4D9] flex justify-center items-center">
          <form
            onSubmit={handleSubmit}
            className="flex flex-col justify-evenly space-y-7 md:space-y-16 lg:space-y-16 w-full max-w-[800px]"
          >
            <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0">
              <div className="flex-1">
                <label htmlFor="firstName" className="sr-only">
                  First Name
                </label>
                <input
                  id="firstName"
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="First Name"
                  required
                  className="w-full bg-transparent border-b border-[#11D4D9] text-[#FFFFFF] placeholder-[#FFFFFF] focus:outline-none focus:border-teal-500 md:text-xl py-2 leading-relaxed"
                />
              </div>
              <div className="flex-1">
                <label htmlFor="lastName" className="sr-only">
                  Last Name
                </label>
                <input
                  id="lastName"
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Last Name"
                  required
                  className="w-full bg-transparent border-b border-[#11D4D9] text-[#FFFFFF] placeholder-[#FFFFFF] focus:outline-none focus:border-teal-500 md:text-xl py-2 leading-relaxed"
                />
              </div>
            </div>

            <div>
              <label htmlFor="email" className="sr-only">
                Email Address
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email Address"
                required
                className="w-full bg-transparent border-b border-[#11D4D9] text-[#FFFFFF] placeholder-[#FFFFFF] focus:outline-none focus:border-teal-500 md:text-xl py-2 leading-relaxed"
              />
            </div>

            {/*---------------- BUTTON ------------------*/}

            {/* <button className="flex items-center justify-between px-4 bg-[#11D4D9] text-white  rounded-md w-max space-x-16 mx-auto border-t-2 border-b-2 border-l-2 lg:w-[247px] lg-h-[44px] outline outline-4 outline-[#004d40]">
              <span className="mr-2 text-black font-normal">JOIN NOW</span>
              <img src={sail} alt="icon" className="w-12" />
            </button> */}

            <button class="px-4 bg-[#11D4D9] rounded-md w-max mx-auto border-t-2 border-b-2 border-l-2 lg:w-[235px] lg-h-[44px] outline outline-4 outline-[#407e80] relative uppercase">
              <span class="absolute -top-[3px] left-1/2 transform -translate-x-1/2 w-2/5 h-1 rounded-full bg-white"></span>
              <span class="tapered-corners absolute bottom-[-9px] left-1/2 transform -translate-x-1/2 w-2/5 h-1 bg-[#11D4D9]"></span>
              <div className="flex items-center justify-between space-x-16 lg:space-x-10 ">
                <p className="mr-2 text-black font-normal tracking-widest">
                  join us
                </p>
                <img
                  src={sail}
                  alt="icon"
                  className="w-10 sm:w-8 sm:h-8 md:w-10 md:h-10"
                />
              </div>
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default JoinUs;
