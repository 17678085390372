import React from "react";
import rect1 from "../images/rect1.svg";
import rect from "../images/rect.svg";
import rect3 from "../images/rect3.svg";
import rect4 from "../images/rect4.svg";
import wavegif from "../images/wavegif.gif";

const WhyAtlaniss = () => {
  return (
    <>
      <div className="bg-[#080D13] py-10" name="/WhyChooseUs">
        <div className="flex justify-center">
          <img src={wavegif} className="w-[84px] flex justify-center" alt="" />
        </div>
        <h1 className="text-white font-extrabold text-3xl md:text-4xl text-center lg:text-heading py-16">
          Why Choose Atlantis{" "}
          <span className="bg-gradient-to-r from-[#CD0E59] to-[#11D4D9] bg-clip-text text-transparent font-medium">
            Digital Twin?
          </span>
        </h1>
        <div className="bg-digitalbg bg-cover space-y-10">
          <div className="md:flex">
            <img src={rect1} className="md:w-1/2" alt="" />
            <div className="flex flex-col items-center justify-center md:items-start px-2 lg:px-20 space-y-5">
              <img src={wavegif} className="w-[84px] " />
              <h1 className="text-2xl lg:text-sub-heading font-bold bg-gradient-to-r from-[#CD0E59] to-[#11D4D9] bg-clip-text text-transparent">
                Enhanced Decision-Making
              </h1>
              <p className="text-p2 text-white w-5/6 text-center md:text-start">
                Leverage real-time data and predictive analytics to make
                informed decisions that drive efficiency and innovation.
              </p>
            </div>
          </div>

          <div className="flex flex-col-reverse items-center md:flex-row justify-between">
            <div className="flex flex-col items-center justify-center md:items-start px-2 lg:px-20 space-y-5">
              <img src={wavegif} className="w-[84px]" />
              <h1 className="text-2xl lg:text-sub-heading font-bold bg-gradient-to-r from-[#CD0E59] to-[#11D4D9] bg-clip-text text-transparent">
                Increased Efficiency
              </h1>
              <p className="text-p2 text-white w-5/6 text-center md:text-start">
                Optimize operations with our advanced tools and technologies,
                reducing costs and improving performance.
              </p>
            </div>
            <img src={rect} className="md:w-1/2" alt="" />
          </div>

          <div className="md:flex">
            <img src={rect3} className="md:w-1/2" alt="" />
            <div className="flex flex-col items-center justify-center md:items-start px-2 lg:px-20 space-y-5">
              <img src={wavegif} className="w-[84px]" />
              <h1 className="text-2xl lg:text-sub-heading font-bold bg-gradient-to-r from-[#CD0E59] to-[#11D4D9] bg-clip-text text-transparent">
                Sustainable Solutions
              </h1>
              <p className="text-p2 text-white w-5/6 text-center md:text-start">
                Promote sustainability through smart resource management and
                eco-friendly initiatives.
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col-reverse md:flex-row justify-between mt-10">
          <div className="flex flex-col items-center justify-center md:items-start px-2 lg:px-20 space-y-5">
            <img src={wavegif} className="w-16" />
            <h1 className="text-2xl lg:text-sub-heading font-bold bg-gradient-to-r from-[#CD0E59] to-[#11D4D9] bg-clip-text text-transparent">
              Innovative Technology
            </h1>
            <p className="text-p2 text-white w-5/6 text-center md:text-start">
              Stay ahead of the curve with the latest advancements in AI, IoT,
              AR, and VR, ensuring your operations are future-proof.
            </p>
          </div>
          <img src={rect4} className="md:w-1/2" alt="" />
        </div>
      </div>
    </>
  );
};

export default WhyAtlaniss;
