import React, { useState, useEffect } from "react";
import CarouselDots from "./components/CarouselDots";
import sail from "../images/Sail.gif";
import welcomebg1 from "../images/welcomebg1.svg";
import welcomebg2 from "../images/welcomebg2.svg";
import welcomebg3 from "../images/welcomebg3.svg";
import welcomebg4 from "../images/welcomebg4.svg";
import wavegif from "../images/wavegif.gif";
import mbbg from "../images/mbbg.svg";

const backgroundImages = [welcomebg1, welcomebg2, welcomebg3, welcomebg4];
const mobileImages = [mbbg, mbbg, mbbg, mbbg];

const WelcomePageCarousel = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % backgroundImages.length
      );
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isMobile]);

  return (
    <>
      <div
        className=" transition-all duration-500 mt-0 max-w-full h-full py-28 md:py-32 xl:py-40 object-fill bg-cover"
        style={{
          backgroundImage: `url(${
            isMobile
              ? mobileImages[currentImageIndex]
              : backgroundImages[currentImageIndex]
          })`,
        }}
        name="/home"
      >
        <div className="px-4 sm:px-8 md:px-16 lg:px-20 space-y-5 md:space-y-7 flex flex-col items-start">
          <img src={wavegif} className="w-[84px]" alt="" />
          {/* Title Section */}
          <h1 className="max-w-screen-xl text-3xl md:text-5xl lg:text-welcome xl:text-7xl font-extrabold text-white">
            WELCOME TO{" "}
            <span className="bg-gradient-to-r from-[#11D4D9] to-[#CD0E59] bg-clip-text text-transparent leading-normal">
              ATLANTIS <br />
              KHALIFA{" "}
            </span>
            PORT
          </h1>

          {/* Subheading */}
          <p className="bg-gradient-to-r from-[#CD0E59] to-[#11D4D9] bg-clip-text text-transparent text-left sm:text-left sm:text-2xl md:text-3xl lg:text-sub-heading">
            The Future of Virtual Exploration
          </p>

          {/* Description Paragraph */}
          <p
            className="text-white text-start sm:text-sm md:text-lg lg:text-p3 xl:text-2xl leading-loose md:w-4/6 xl:w-[780px] xl:"
            style={{ fontFamily: "Inter-Regular" }}
          >
            Experience the magic of Atlantis Khalifa Port from anywhere in the
            world. Dive into an immersive digital twin of one of the most
            advanced ports and explore its wonders like never before.
          </p>

          {/* Button Section */}
          <div className="flex justify-start ">
            <button class="bg-[#11D4D9] text-black border-t-2 border-b-2 border-l-2 outline outline-4 outline-[#407e80] px-4 md:py-0 md:px-3 uppercase relative rounded-[5px] shadow-md">
              <span class="absolute -top-[3px] left-1/2 transform -translate-x-1/2 w-2/5 h-1 rounded-full bg-white"></span>
              <span class="tapered-corners absolute bottom-[-9px] left-1/2 transform -translate-x-1/2 w-2/5 h-1 bg-[#11D4D9]"></span>
              <div className="flex items-center justify-between  space-x-6">
                <p
                  className="font-normal tracking-widest"
                  onClick={() =>
                    window.open("https://atlantis-explore.zip2box.com/")
                  }
                >
                  EXPLORE MORE
                </p>
                <img
                  src={sail}
                  alt="icon"
                  className="w-10 sm:w-8 sm:h-8 md:w-10 md:h-10"
                />
              </div>
            </button>
          </div>
        </div>

        {/* Carousel Dots */}
        <CarouselDots
          currentImageIndex={currentImageIndex}
          backgroundImages={backgroundImages}
          onDotClick={setCurrentImageIndex}
        />
      </div>
    </>
  );
};

export default WelcomePageCarousel;
