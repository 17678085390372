import React from "react";
import fb from "../images/fb.svg";
import insta from "../images/insta.svg";
import lin from "../images/lin.svg";
import tr from "../images/tr.svg";
import newlogo from "../images/newlogo.svg";
import { Link } from "react-router-dom";

const solutions = [
  { name: "Solution", link: "" },
  { name: "Faction", link: "" },
  { name: "Promatheus", link: "" },
];

const company = [
  { name: "About Us", link: "" },
  { name: "Team", link: "" },
  { name: "Advisors", link: "" },
];

const contactDetails = [
  { info: "info@atlantisdigitaltwin.com", type: "email" },
  { info: "Phone: +123-456-7890", type: "phone" },
  { info: "Address: 123 Future Street,", type: "address" },
  { info: "Innovation City, Country", type: "address" },
];

const Footer = () => {
  return (
    <footer className="bg-[#070C11]" name="tryit">
      <div className="mx-auto w-screen xl:w-full px-4 max-w-screen-2xl p-4 md:py-12 md:px-10 xl:px-20 text-[#FFFFFF] space-y-5">
        <div className="space-y-10 md:space-y-0 md:flex md:justify-between">
          {/*------------------- left side --------------------*/}
          <div className="mb-6 md:mb-0 md:w-3/6 lg:w-2/6 space-y-7 lg:px-0">
            <div className="flex flex-col items-end w-max">
              <div className="text-white text-Atlantis tracking-20-percent font-black">
                ATLANTIS
              </div>
              <div className="text-white flex">
                Powered by
                <img src={newlogo} alt="Footer Logo" className="w-36" />
              </div>
            </div>

            <p className="text-xs leading-loose text-[#FFFFFF]">
              Embark on your digital transformation journey with Atlantis
              Digital Twin. Contact us to learn more about how our platform can
              revolutionize your operations and unlock new possibilities.
            </p>
          </div>
          {/*------------- right side ----------------*/}
          <div className="grid grid-cols-2 lg:grid-cols-3 md:px-10 lg:px-0">
            {/* Solutions Section */}
            <div>
              <h2 className="mb-6 text-sm font-semibold text-[#E6E6E8] uppercase">
                Solutions
              </h2>
              <ul className="text-[#FFFFFF] font-medium text-sm">
                {solutions.map((solution, index) => (
                  <li className="mb-4" key={index}>
                    <Link to={solution.link} className="hover">
                      {solution.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            {/* Company Section */}
            <div>
              <h2 className="mb-6 text-sm font-semibold text-[#E6E6E8] uppercase">
                Company
              </h2>
              <ul className="text-[#FFFFFF] font-medium text-sm">
                {company.map((item, index) => (
                  <li className="mb-4" key={index}>
                    <Link to={item.link} className="hover">
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            {/* Contact Section */}
            <div className="mt-10 lg:mt-0">
              <h2 className="mb-6 text-sm font-semibold text-[#E6E6E8] uppercase">
                Contact Us
              </h2>
              <div className="text-sm text-[#FFFFFF] space-y-4">
                {contactDetails.map((contact, index) => (
                  <p key={index} className="hover:underline">
                    {contact.info}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>

        <hr className="border-gray-700 sm:mx-auto" />

        <div className="sm:flex sm:items-center sm:justify-between">
          <p className="text-sm text-[#FFFFFF] text-start w-[265px] lg:w-max">
            © Copyright 2024 Atlantis digital twin | All Rights Reserved |
          </p>

          <div className="flex sm:justify-center mt-5 md:mt-0 ">
            <Link to="" className="text-gray-400 hover:text-white">
              <img src={fb} alt="Facebook" className="w-12 transition-transform duration-500 ease-in-out hover:rotate-45" />
              <span className="sr-only">Facebook page</span>
            </Link>
            <Link to="" className="text-gray-400 hover:text-white ms-5">
              <img src={tr} alt="Twitter" className="w-12 transition-transform duration-500 ease-in-out hover:rotate-45" />
              <span className="sr-only">Twitter page</span>
            </Link>
            <Link to="" className="text-gray-400 hover:text-white ms-5">
              <img src={insta} alt="Instagram" className="w-12 transition-transform duration-500 ease-in-out hover:rotate-45" />
              <span className="sr-only">Instagram page</span>
            </Link>
            <Link to="" className="text-gray-400 hover:text-white ms-5">
              <img src={lin} alt="LinkedIn" className="w-12 transition-transform duration-500 ease-in-out hover:rotate-45" />
              <span className="sr-only">LinkedIn page</span>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
