import React from 'react';

const CarouselDots = ({ currentImageIndex, backgroundImages, onDotClick }) => {
  return (
    <div className="absolute top-2/3 right-4 transform -translate-y-1/2 flex flex-col space-y-7">
      {backgroundImages.map((_, index) => (
        <button
          key={index}
          className={`w-3 h-3 rounded-full transition-all duration-1000 ${
            index === currentImageIndex
              ? "bg-[#CD0E59] scale-125"
              : "bg-white bg-opacity-50"
          }`}
          onClick={() => onDotClick(index)}
          aria-label={`Go to slide ${index + 1}`}
        />
      ))}
    </div>
  );
};

export default CarouselDots;
