import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./LandingPage/Index";
import WelcomePageCarousel from "./LandingPage/WelcomeCarousel";
import WhyAtlaniss from "./LandingPage/WhyAtlanis";
// import LandingCarousel from "./LandingPage/LandingCarousel";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
