import React, { useState } from "react";
import { Link } from "react-scroll";
import { FaBars, FaTimes } from "react-icons/fa";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="py-4 w-screen px-4 md:px-12 lg:pl-28 flex justify-between items-center bg-[#001A1B] lg:bg-transparent fixed z-20 backdrop-blur-lg">
        <h1 className="text-4xl md:text-Atlantis font-bold text-white tracking-10-percent">
          ATLANTIS
        </h1>
        <div className="flex lg:hidden">
          <button onClick={toggleMenu} className="text-white text-2xl">
            {isOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
        <div
          className={`flex-col text-center lg:flex lg:flex-row lg:items-center gap-4 lg:mb-auto xl:gap-10 text-white text-sm xl:text-xl absolute lg:static top-16 left-0 w-full lg:w-auto bg-[#001A1B] lg:bg-transparent transition-transform transform ${
            isOpen ? "translate-x-0" : "-translate-x-full lg:translate-x-0 "
          }`}
        >
          <Link
            to="/home"
            spy={true}
            smooth={true}
            offset={50}
            duration={2000}
            className="block lg:inline-block px-4 py-4 hover:cursor-pointer lg:hover:scale-105 border-b border-[#2d2b2b] lg:border-none"
          >
            Home
          </Link>
          <Link
            to="/WhyChooseUs"
            spy={true}
            smooth={true}
            offset={50}
            duration={1000}
            className="block md:inline-block px-4 py-4 hover:cursor-pointer lg:hover:scale-105 border-b border-[#2d2b2b] lg:border-none"
          >
            Why Choose Us
          </Link>
          <Link
            to="/explore"
            spy={true}
            smooth={true}
            offset={50}
            duration={1000}
            onClick={() =>
              window.open("https://atlantis-explore.zip2box.com/", "_blank")
            }
            className="block lg:inline-block px-4 py-4 hover:cursor-pointer lg:hover:scale-105 border-b border-[#2d2b2b] lg:border-none"
          >
            Explore
          </Link>
          <Link
            to="/contact"
            spy={true}
            smooth={true}
            offset={50}
            duration={1000}
            className="block lg:inline-block px-4 py-4 hover:cursor-pointer lg:hover:scale-105 border-b border-[#2d2b2b] lg:border-none"
          >
            Contact Us
          </Link>
          <Link
            to="tryit"
            spy={true}
            smooth={true}
            offset={50}
            duration={1000}
            className="hidden lg:block px-4 py-2 md:px-8 md:py-1 lg:text-[10px] hover:cursor-pointer lg:hover:scale-105 lg:bg-[#CD0E59] rounded-full "
          >
            Try It
          </Link>

          {/**sm try it */}
          <Link
            to="tryit"
            spy={true}
            smooth={true}
            offset={50}
            duration={1000}
            className="block pb-6 py-6 lg:hidden "
          >
            <span className="bg-[#CD0E59] px-10 py-3 rounded-full">Try it</span>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Header;

// ${isOpen ? 'translate-x-0' : '-translate-x-full md:translate-x-0'}
