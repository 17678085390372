import React from "react";
import connectivity from "../images/connectivity.png";
import customization from "../images/customization.png";
import boatgif from "../images/boatgif.gif";

const Discover = () => {
  // Data array to hold repeated content
  const contentData = [
    {
      imgSrc: connectivity,
      title: "Connectivity",
      description: `Atlantis Khalifa Port Metaverse is not just a place to explore – it's a place 
                    to connect. Meet like-minded individuals, collaborate on projects, and 
                    participate in events, all within our dynamic virtual space. Our social 
                    spaces and collaboration tools are designed to foster meaningful 
                    connections and facilitate seamless communication.`,
    },
    {
      imgSrc: connectivity,
      title: "Immersive Experiences",
      description: `Step into a world of limitless possibilities with our virtual tours and interactive
                    experiences. Whether you're a history enthusiast, a technology aficionado, or
                    simply curious, Atlantis Khalifa Port Metaverse offers something for everyone.
                    Explore the port's state-of-the-art facilities, stunning architecture, and vibrant
                    community through high-quality visuals and engaging narratives.`,
    },
    {
      imgSrc: customization,
      title: "Customization",
      description: `Make Atlantis Khalifa Port Metaverse your own. Customize your avatar,
                    personalize your environment, and unlock unique features that reflect
                    your style and preferences. Our platform offers a range of tools to help
                    you create a virtual presence that's as unique as you are.`,
    },
  ];

  return (
    <div className="py-10 md:pb-24 bg-cargosvg bg-cover max-w-screen h-full">
      <div className="flex flex-col lg:flex-row justify-between">
        {/* "Discover Atlantis" on top for small screens */}
        <div className="flex lg:hidden items-center justify-center text-center">
          <div className="text-4xl sm:text-5xl font-extrabold text-white">
            Discover Atlantis
            <br />
            <span className="bg-gradient-to-r from-[#CD0E59] to-[#11D4D9] bg-clip-text text-transparent font-normal">
              Digital Twin
            </span>
          </div>
        </div>

        {/* Left side with images and content */}
        <div className="w-full lg:w-3/5 px-2 sm:px-12 md:px-16 grid-rows-8">
          <div className="mt-10">
            <div>
              {contentData.map((item, index) => (
                <div key={index} className=" border-b border-dashed">
                  <img
                    src={item.imgSrc}
                    className="w-[80px] h-[51px]  mt-16"
                    alt={item.title}
                  />
                  <h1 className="font-bold text-3xl md:text-4xl lg:text-sub-heading text-[#11D4D9] my-5">
                    {item.title}
                  </h1>
                  <p className="text-sm sm:text-base lg:text-p2 text-white md:w-[539px] md:-mb-10">
                    {item.description}
                  </p>
                  <div className="w-auto">
                    <img
                      src={boatgif}
                      alt="Boat"
                      className="w-10 md:w-[54px] ms-auto"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Right side with rotated text for medium+ screens */}
        <div className="hidden lg:flex items-center justify-items-end">
          <div className="rotate-90 text-end -mr-20 xl:-mr-36 -mb-56">
            <h1 className="font-extrabold text-[46px] xl:text-[64px] text-white">
              Discover Atlantis<br/>
              <span className="bg-gradient-to-r from-[#CD0E59] to-[#11D4D9] bg-clip-text text-transparent font-normal">
                Digital Twin
              </span>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Discover;

// {item.description.split("\n").map((line, i) => (
//   <React.Fragment key={i}>
//     {line}
//     <br />
//   </React.Fragment>
// ))}

{
  /* <div className="hidden lg:flex h-screen mt-36 overflow-hidden md:w-2/6 grid-rows-4">
          {" "}
          <div className="flex flex-col relative rotate-90 text-end lg:text-[38px] xl:text-[52px]">
            <h1 className="text-white font-extrabold ">Discover Atlantis</h1>{" "}
            <h2 className="bg-gradient-to-r from-[#CD0E59] to-[#11D4D9] bg-clip-text text-transparent font-normal pb-2">
              Digital Twin
            </h2>
          </div>
        </div> */
}
